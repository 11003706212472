const Projectdata=[
    {
     img:'https://mattfarley.ca/img/projects/wfdesignbuild.png',
     title: "High-end, custom residental renovators serving Fraser Valley homeowner"
},
{
    img:'https://mattfarley.ca/img/projects/burkettandco.png',
    title: "High-end, custom residental renovators serving Fraser Valley homeowner"
},
{
    img:'https://mattfarley.ca/img/projects/chronicled.png',
    title: "High-end, custom residental renovators serving Fraser Valley homeowner"
},
{
    img:'https://mattfarley.ca/img/projects/glcsolutions.png',
    title: "High-end, custom residental renovators serving Fraser Valley homeowner"
},
{
    img:'https://mattfarley.ca/img/projects/coupal.png',
    title: "High-end, custom residental renovators serving Fraser Valley homeowner"
},
{
    img:'https://mattfarley.ca/img/projects/wedlerengineering.png',
    title: "High-end, custom residental renovators serving Fraser Valley homeowner"
}
];
export default Projectdata;